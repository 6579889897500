@import "theme";

/* Bootstrap class overrides */

//Buttons

.btn{
    border-radius: 2px;
}

.btn-primary {
    background: $primary-button-gradient;
    border-color: $primary-button-color;
}

.btn-primary:hover {
    border-color: $primary-button-color;
}

.btn-primary.focus,
.btn-primary.focus {
    border-color: $primary-button-color;
    box-shadow: 0 0 0 0.2rem $primary-button-shadow-color !important;
}

.btn-primary:focus,
.btn-primary.focus {
    border-color: $primary-button-color;
    box-shadow: 0 0 0 0.2rem $primary-button-shadow-color !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
    border-color: $primary-button-color;
    cursor: default !important;
}


//secondary button
.btn-secondary {
    background: $secondary-button-gradient;
    border-color: $primary-button-color;
    color: $primary-color !important;
}

.btn-secondary:hover {
    border-color: $primary-button-color;
}

.btn-secondary.focus,
.btn-secondary.focus {
    border-color: $primary-button-color;
    box-shadow: 0 0 0 0.2rem $primary-button-shadow-color !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
    border-color: $primary-button-color;
    box-shadow: 0 0 0 0.2rem $primary-button-shadow-color !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    border-color: $primary-button-color;
    cursor: default !important;
}


// link
a {
    color: $highlight-text-color;
}

a:hover {
    color: $highlight-text-color-dark;
}

a:focus {
    outline: 0 !important;
}

.btn-link {
    color: $highlight-text-color;
}

.btn-link:hover {
    color: $highlight-text-color-dark;
}

.btn-link:focus {
    outline: 0 !important;
}

//Input number

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

//form control
.form-control {
    border-radius: 0 !important;
    height: 32px;
}

.form-control:focus {
    background-color: #fff;
    border-color: $secondary-border-color;
    outline: 0;
    box-shadow: 0 0 0 0 rgb(0 123 255 / 25%);
}
