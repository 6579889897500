// text colors
$highlight-text-color: #118799;
$highlight-text-color-dark: #0f6e7c;
$primary-text-color: #53565a;
$active-tab-color: #ed8b00;
$primary-orange-color: #ed8b00;

//theme colors
$body-background: #b8d4de;
$primary-color: #118799;
// $secondary-color: #53565a;
$primary-button-color: #2b7272;
$primary-button-shadow-color: #46b9b9;
$primary-button-gradient: linear-gradient(360deg, #2b7272 51.79%, #379392 51.93%), #ffffff;
$secondary-button-gradient: linear-gradient(180deg, #fff 51.79%, #e5f2f4 51.93%), #ffffff;
$primary-border-color: #118799;
$secondary-border-color: #cfdde6;
$primary-shadow-color: rgba(163, 170, 174, 0.45);

//
$default-font-size: 14px;
