/* You can add global styles to this file, and also import other style files */

// Importing fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";

/* Importing Custom SCSS file. */

@import "assets/styles/theme.scss";
@import "assets/scss/variables.scss";
@import "assets/styles/bootstrap.override.scss";
@import "assets/styles/ng-select.override.scss";

/* Importing Ng-select Style file. */

@import "~@ng-select/ng-select/themes/default.theme.css";

/* Global */

html,
body {
    margin: 0;
    padding: 0;
    font-size: $default-font-size;
    font-family: Roboto, sans-serif;
    color: $primary-text-color;
    background-color: #e5e5e5;
    min-width: 1350px;
    overflow-x: auto;
    cursor: default;
}
body{
    overflow-y: auto;
}
.pagination .ngx-pagination .current {
    background: #27a6a4;
    color: white;
    border-color: unset;
    cursor: default;
    border-radius: 4px;
    height: 33px;
    width: 33px;
    align-items: center;
    display: inline-grid;
    flex-direction: column;
    margin-right: 10px;
    justify-content: center;
}

ul {
    padding-inline-start: 0px;
}

// NgbTooltip Custom Class
.tooltipCustomClass .tooltip-inner {
    background-color: #ffffff;
    border: 1px solid #999999;
    color: black;
    min-width: 250px !important;
}

.tooltipCustomClass .arrow::before {
    border-right-color: #999999 !important;
}

.ngx-pagination {
    margin-left: 0;
    margin-bottom: 1rem;
    margin-top: 1rem !important;
}

a {
    font-weight: 500;
}

a:hover {
    color: #27a6a4;
    font-weight: bold;
    font-weight: 500;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: none !important;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none !important;
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: #dee2e6 !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: gray transparent transparent !important;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: gray transparent !important;
}
.search-container {
    .ng-select {
        height: 43px;
        //max-width: 133px;
        .ng-select-container {
            min-height: 43px;
            max-height: 43px;
        }
    }
    .ng-select.ng-select-multiple
        .ng-select-container
        .ng-value-container
        .ng-placeholder {
        top: 10px;
    }
}
.headStyle {
    .reset-link{
        color: #27A6A4 !important;
        display: inline-flex;
        padding: 10px;
        cursor: pointer;
    }
}
.filter-width{
    max-width: 21%;
    flex: 0 0 21%;
}
.cancelBtn{
    height: 35px;
    background-color: #6c7575 !important;
    border: 1px solid #6c7575 !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 0px;
    width: 55px !important;
    margin-left: 15px;
    font: normal 400  14px/22px "Roboto";
    text-align: center;
    color: #FFFFFF !important;
}
// data concierge modal styles
.modal-q-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: #1a1628 !important;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    .close {
        color: #ffffff;
    }
}
.modal-success-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 1rem;
    font-weight: 700;
    font-size: 18px;
    .close {
        color: black;
    }
}
.modal-body {
    .text-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2f2c3c;
    }
    .name-text {
        height: 32px;
        width: 100% !important;
        background: #ffffff;
        border: 1px solid #dedcde;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .name-text-area {
        background: #ffffff;
        border: 1px solid #dedcde;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .search-list-row {
        border-bottom: 1px solid #dee2e6;
        padding: 10px;
    }
    .btn-chat{
        background-color: #27A6A4 !important;
        border-color: #27A6A4 !important;
        width: 25px;
    }
}
.saveSearch{
    cursor: pointer;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #DEDCDE;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 2px;
    margin: -3px 1px 1px 1px;
    width: 178px;
    font-size: 16px;
    img{
        width: 16px;
        vertical-align: -2px;
        margin-right: 10px;
    }
}
.multiselect-dropdown {
    .dropdown-list{
        .item1{
            .multiselect-item-checkbox{
                position: relative;
                top: 41px;
            }
            .filter-textbox{
                top: -45px;
            }
        }
    }
    .dropdown-btn{
        border: 1px solid #cfdde6 !important;
        .dropdown-multiselect__caret{
            &::before{
                border-width: 6px 5px 0 !important;
            }
        }
    }
}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item{
    border: none !important;
    background: none!important;
    color: #2F2C3C!important;
    max-width: unset !important;
    min-width: 100px;
    a{
        color: #2F2C3C!important;
    }
}

//ng select readonly styles - request management
.request-form {
    .ng-select.disable-dropdown ng-dropdown-panel {
        display: none;
    }
    .ng-select.disable-clear-all .ng-clear-wrapper {
        display: none;
    }
    .ng-value .ng-value-icon{
        display: none !important;
    }
}

.cart-btn{
    font-size:12px ;
    padding: 5px 6px;
    color: #666;
    &.disableBtn{
        pointer-events: none;
    }
}
.table-secondary,
.table-secondary td,
.table-secondary th {
    background-color: white !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{
    padding-left: 10px;
}
.ng-optgroup{
    input{
        margin-right: 5px;
    }
}
.accCard {
    .accordion > .card{
        overflow: unset;
    }
}
.form-group{
    .ng-dropdown-panel .ng-dropdown-header{
        border-bottom: none;
    }
}
.search-container .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: unset;
}
// home page custom select styles
.search-container {
    .searchBox {
        height: 42px !important;
        width: 100% !important;
        outline: none;
        border: 2px solid #716E79 !important;
        border-right: none !important;
    }
    .search-btn {
        height: 42px;
        background-color: #ffffff !important;
        border: 2px solid #716E79 !important;
        border-radius: 0px 10px 10px 0px !important;
        border-left: 0 !important;
    }
    .main-custom-select{
        min-width: 135px;
        max-width: 135px;
        .ng-select-container{
            border: 2px solid #716E79;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 10px 0px 0px 10px;
            border-right: 0;
            min-height: 42px !important;
            color: #5B5864;
        }
    }
}

.dropdown-menu{
    top: 15px !important;
    width: 200px !important;
}

.rating-star{
    .ratingHover{
        span{
            cursor: pointer !important;
        }
    }
}

.btn-info{
    background-color: $secondary-color;
    border-color: $secondary-color;
}
.mandatory-symbol{
    font-size: 14px;
    color: #E65428;
    display: inline-block;
    margin-right: 5px;
}
.secondary-btn{
    background-color: #6c7575 !important;
    border: 1px solid #6c7575 ;
    border-radius: 2px !important;
    text-align: center;
    color: #FFFFFF !important;
}
.decline-btn{
    background-color: #ffffff;
    border: 1px solid #27a6a4 !important;
    border-radius: 2px !important;
    text-align: center;
    color: #27a6a4;
}

.btn-disabled{
    background-color: #6c7575 !important;
    border: none !important;
    opacity: 0.5;
}

.full-screen-modal-width .modal-dialog {
    max-width: 90vw;
}