//ng-select style

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option {
    display: flex !important;
    align-items: center;
    white-space: break-spaces;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-radius: 2px 2px 2px 2px !important;
}
.ng-select .ng-select-container {
    border: 1px solid #cfdde6;
    border-radius: 2px;
}

.ng-select:focus {
    outline: 0;
}

.ng-select .ng-select-container .ng-value-container {
    border-radius: 2px 2px 2px 2px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 34px;
}

.ng-select .ng-select-container {
    min-height: 34px;
    max-height: 34px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-bottom: 6px;
    padding-top: 6px;
    padding-left: 7px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent $highlight-text-color;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: $highlight-text-color transparent transparent;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 14px;
}

.ng-dropdown-panel.ng-select-bottom {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid #cfdde6;
    box-shadow: 0 0 0 0.2rem #aed6d6;
}

//.ng-dropdown-panel .ng-dropdown-panel-items {
//    max-height: 320px !important;
//}

.ng-dropdown-panel .ng-dropdown-panel-items {
    min-height: 150px;
}

.ng-dropdown-panel .ng-dropdown-header {
    padding-bottom: 0 !important;
}

ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: #ffffff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: #ffffff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
}

.ng-select-selected-items {
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    display: flex !important;
}


/*---------------------------*/

#searchSelect {
    .ng-option {
        pointer-events: none !important;
    }
}
